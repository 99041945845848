<template>
  <MainTable :table-props="tableProps">
    <thead class="thead-table-paranje">
      <tr>
        <th class="uk-table-expand uk-width-small">
          <span class="uk-text-bold">Kode Vendor</span>
        </th>
        <th class="uk-table-expand uk-width-expand">
          <span class="uk-text-bold">Nama Vendor</span>
        </th>
        <th class="uk-table-expand uk-width-small">
          <span class="uk-text-bold">Deskripsi</span>
        </th>
        <th class="uk-table-expand uk-width-small">
          <span class="uk-text-bold">Terakhir Diubah</span>
        </th>
        <th class="uk-table-small uk-width-small uk-text-center">
          <span class="uk-text-bold">Status</span>
        </th>
      </tr>
    </thead>
    <template v-if="!is_loading">
      <tbody v-if="dataVendor.meta.total_count>0">
        <tr
          v-for="(doc, i) in dataVendor.data"
          :key="i"
        >
          <td>{{ doc.code || '-' }}</td>
          <td>{{ doc.name || '-' }}</td>
          <td>{{ doc.description || '-' }}</td>
          <td>{{ formatDate(doc.updated_at) || '-' }}</td>
          <td style="text-align: center">
            <label-status :status="doc.status == 'active' ? true : false || false" />
          </td>
        </tr>
      </tbody>
      <empty-table
        v-else
        :colspan="7"
      />
    </template>
    <template v-else>
      <loading-table :colspan="7" />
    </template>
  </MainTable>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import LabelStatus from '@/components/globals/LabelStatus'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'
import MainTable from '@/components/globals/table'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    LabelStatus,
    MainTable
  },
  data() {
    return {
      is_loading: false,
      status: STATUS,
      images: PREFIX_IMAGE,
      tableProps: {
        total_count: 0
      }
    }
  },
  computed : {
    ...mapGetters({
      dataVendor: 'vendor/dataVendor',
      meta: 'vendor/meta'
    })
  },
  watch: {
    async meta() {
      this.is_loading = true
      await this.getDataVendor(this.meta)
      this.is_loading = false
    },
    dataVendor() {
      if (this.dataVendor) {
        this.tableProps ={
          ...this.meta,
          meta: this.meta,
          setMeta: this.setMeta,
          total_count: this.dataVendor.meta.total_count
        }
      }
    }
  },
  async mounted() {
    await this.getDataVendor(this.meta)
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getDataVendor: 'vendor/getDataVendor'
    }),
    ...mapMutations({
      setMeta: 'vendor/SET_META'
    }),
    showDetail (id) {
      this.$router.push(`/admin/vendor/detail/${id}`)
    }
  }
}
</script>
