<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-1-2@s font-header">
        {{ headerTitle }}
      </div>
      <div class="uk-width-1-2@s uk-text-right">
        <button
          v-if="!is_loading"
          class="uk-button uk-button-primary uk-margin-left"
          :disabled="is_loading"
          @click="handleSync"
        >
          <img
            v-lazy="`${images}/icon/sync.svg`"
            alt=""
            class="uk-margin-small-right img-click"
          >
          Sync Data
        </button>
        <button
          v-else
          style="cursor:unset; min-width:167.47px;"
          class="uk-button uk-button-primary uk-margin-left"
        >
          <div uk-spinner />
        </button>
      </div>
    </div>

    <container />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import getHeaderTitle from '@/utils/header-title'
import Container from './container'
import { dateString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  components: {
    Container
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      is_loading: false
    }
  },
  computed: {
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    },
    ...mapGetters({
      meta: 'vendor/meta',
      syncVendor: 'vendor/syncVendor'
    })
  },
  methods: {
    ...mapActions({
      syncVendorData: 'vendor/getSyncVendor'
    }),
    ...mapMutations({
      setMeta: 'vendor/SET_META'
    }),
    getDatetime(date) {
      return dateString(date)
    },
    async handleSync () {
      this.is_loading = true
      await this.syncVendorData()
      if (this.syncVendor.message == 'OK') {
        notificationSuccess(`Sync Data Berhasil`)
        await this.setMeta({
          ...this.meta
        })
      }
      this.is_loading = false
    }
  }
}
</script>
